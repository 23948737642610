// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
  apiKey: "AIzaSyBE4qivsf4hb-P98SmozOtqaYvivKQ7AhU",
  authDomain: "investing-daddy.firebaseapp.com",
  projectId: "investing-daddy",
  storageBucket: "investing-daddy.appspot.com",
  messagingSenderId: "159510357179",
  appId: "1:159510357179:web:39b02dfc42cab0499f759e",
  measurementId: "G-RRP4199HWQ",
    // vapidKey: "AAAAJSOO5Ls:APA91bFT_JyCMUNLVVmxy9ocSNQUwJI8FdsP9RVYAv8snbu3PIw_-rpnQASUqnxtLXeTVCO5XGL-qbHAMJPe4wgybhsxMA4bd0uLntRLoQrm21_Rz_14QT1g6tm_uZAkdp11lj1j7b0C"
    vapidKey:"BCZJkhLKDhMZNtHtKCbtakr9YYjdIPbyZDxuZqTPUW7tAJOlG92oMCDTHFmlH8VdhmkqRVjzhDshbvi5ozsj-sc"


    
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
