import { HttpClient } from '@angular/common/http';
import { MyeventService } from './myevent.service';
import { AppPage } from './../../e2e/src/app.po';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { PushNotifications } from '@capacitor/push-notifications';
import { Keyboard } from '@capacitor/keyboard';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  public AppPage=[
    {
      title: 'home',
      url: '/home',
      icon: 'zmdi zmdi-home'
    },
    {
      title: 'login',
      url: '/login',
      icon: 'zmdi zmdi-home'
    },
  ]
  platformName="other"
  fcm_token = {
    fcmtoken : localStorage.getItem('fcm'),
    os:""
  };
  
  title = 'LTP Calculator';
  message:any = null;
  routerEvents: any;
  linkType:any
  tk:any;
  to:any;
  token1:any;
  usermobile={
    "password":"",
    "username":"",
    "action" :  "loginWithLTP",
    "deviceId" : "",
    "deviceos" : "",
    "token":""
  }
  islog
  constructor(public httpClient:HttpClient,private route: ActivatedRoute, private router:Router,public navCtrl: NavController,public web:MyeventService,private platform:Platform,public toastCtrl: ToastController)
   {
  //  this.router.navigateByUrl('splash')
    var loginflag= localStorage.getItem("loginflag");
    this.token1=localStorage.getItem("token")
    if(this.platform.is('ios'))
      this.web.deviceOs="L1tzDNjMVKeMpjNYtdOs"
    if(this.platform.is('android'))
      this.web.deviceOs="FWfliv40909Pe0RPytrx"
    this.initializeApp();
    
    // if(this.platform.is('ios'))
    //   this.platformName="ios"
    // if(this.platform.is('android'))
      this.platformName="web"
     
      this.fcm_token.os=this.platformName

      this.web.fcm_token.os=this.platformName;
      
    this.web.postData(this.web.fcm_token, '').then((result) => {
   //   this.openfToast();
    });
    if(!loginflag)
    {
    //  navCtrl.navigateRoot("/login");
    web.logFlag=false;
    web.auth='u0wUwWunkn7TSUVNgLYq'

    }else
    {
      web.logFlag=true;
      this.web.user=JSON.parse(localStorage.getItem("userdata"));
      // console.log(this.web.user);
      this.web.auth= localStorage.getItem("token");
      
    }
    
    
  }
  // requestPermission() {
  //   const messaging = getMessaging();
  //   // console.log(environment.firebase.vapidKey)
  //   getToken(messaging, 
  //    { vapidKey: environment.firebase.vapidKey}).then(
  //      (currentToken) => {
  //       // console.log(currentToken);
  //        if (currentToken) {
  //         //  console.log("Hurraaa!!! we got the token.....");
  //          this.update_fcm(currentToken)
  //         //  console.log(currentToken);
  //        } else {
  //         //  console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       // console.log(err);
  //   });
  // }
  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     // console.log('Message received. ', payload);
  //     this.message=payload;
  //   });
  // }
  // requestPermission() {
  //   const messaging = getMessaging();
  //   getToken(messaging, 
  //    { vapidKey: environment.firebase.vapidKey}).then(
  //      (currentToken) => {
  //        if (currentToken) {
  //          console.log("Hurraaa!!! we got the token.....");
  //          console.log(currentToken);
  //        } else {
  //          console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //   });
  // }
  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     this.message=payload;
  //   });
  // }
  async openToast() {
    const toast = await this.toastCtrl.create({
      message: 'Fcm token updated successfully',
      duration: 2000
    });
    toast.present();
  }
  update_fcm(token) {
   
  //   if(this.platform.is('ios'))
  //   this.platformName="ios"
  // if(this.platform.is('android'))
    this.platformName="fK2fjt3DcAS4F4K1v5xc "
     this.fcm_token = {
      fcmtoken: token,
       os:this.platformName
     };
     this.web.fcm_token.fcmtoken=token;
     this.web.fcm_token.os=this.platformName;
     console.log("as"+JSON.stringify(this.web.fcm_token));
    // console.log(this.fcm_token)
     this.web.postData(this.web.fcm_token, '').then((result) => {
       //this.openToast(result);
       console.log(result)
     });
   }
  initializeApp() {
    this.platform.ready().then(() => {
      Keyboard.setAccessoryBarVisible({ isVisible: true});
    });
  }
  ngOnInit()
  {
    this.web.presentLoadingWithOptions();
    this.requestPermission();
    this.listen();
    this.route.queryParamMap.subscribe((params) => {
      this.linkType = { ...params.keys, ...params };
      console.log(this.linkType);
      this.to=this.linkType.params.token
      console.log(this.to)
      if(this.to!=undefined)
        {
      
          this.usermobile.deviceos=this.web.deviceOs
          console.log("plat",this.usermobile.deviceos)
        

          this.usermobile.token=this.to
      
          this.web.postData(this.usermobile,'').then((res)=>{
          
            // console.log(this.payment);
              var temp:any=res;
              console.log(temp)
              if(temp.iUserId){
                localStorage.setItem("loginflag","1")
                localStorage.setItem("userdata",JSON.stringify(temp));
                localStorage.setItem("token",temp.sToken);
                this.web.auth=temp.sToken;
                this.islog=true;
                //this.navCtrl.navigateRoot("/")
                this.web.stopLoad()
                window.location.href="/"
              }
              else
              {
                this.web.presentToastWithOptions(temp.err)
              }

  });
        }
      this.web.logintoken=this.to
      localStorage.setItem("Logintoken",this.to);
      console.log(this.web.logintoken)
    }
    );
    // this.listen();
    // const addListeners = async () => {
    //   await PushNotifications.addListener('registration', token => {
    //     console.info('Registration token: ', token.value);
    //   });
    
    //   await PushNotifications.addListener('registrationError', err => {
    //     console.error('Registration error: ', err.error);
    //   });
    
    //   await PushNotifications.addListener('pushNotificationReceived', notification => {
    //     console.log('Push notification received: ', notification);
    //   });
    
    //   await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    //     console.log('Push notification action performed', notification.actionId, notification.inputValue);
    //   });
    // }
    
    // const registerNotifications = async () => {
    //   let permStatus = await PushNotifications.checkPermissions();
    
    //   if (permStatus.receive === 'prompt') {
    //     permStatus = await PushNotifications.requestPermissions();
    //   }
    
    //   if (permStatus.receive !== 'granted') {
    //     throw new Error('User denied permissions!');
    //   }
    
    //   await PushNotifications.register();
    // }
    // addListeners();
    // registerNotifications();
    const getDeliveredNotifications = async () => {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      // console.log('delivered notifications', notificationList);
    }
  }
  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, 
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
          //  console.log("Hurraaa!!! we got the token.....");
          //  console.log(currentToken);
           this.update_fcm(currentToken)

         } else {
          //  console.log('No registration token available. Request permission to generate one.');
           this.update_fcm("")
         }
     }).catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
        this.update_fcm("")

    });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      // console.log('Message received. ', payload);
      this.message=payload;
    });
  }
  
}
