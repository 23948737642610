import { SignupComponent } from './signup/signup.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)

  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  // {
  //   path: 'signup',
  //   component:SignupComponent
  // },
  {
    path: 'signup/:mobile',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses/courses.module').then( m => m.CoursesPageModule)
  },
  {
    path: 'all-courses/:id',
    loadChildren: () => import('./all-courses/all-courses.module').then( m => m.AllCoursesPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'view-course/:pageid',
    loadChildren: () => import('./view-courses/view-courses.module').then( m => m.ViewCoursesPageModule)
  },
  {
    path: 'read-more/:pageids',
    loadChildren: () => import('./read-more/read-more.module').then( m => m.ReadMorePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'up-date-page',
    loadChildren: () => import('./up-date-page/up-date-page.module').then( m => m.UpDatePagePageModule)
  },
  {
    path: 'cource-details',
    loadChildren: () => import('./cource-details/cource-details.module').then( m => m.CourceDetailsPageModule)
  },
  {
    path: 'zoommeeting',
    loadChildren: () => import('./zoommeeting/zoommeeting.module').then( m => m.ZoommeetingPageModule)
  },
  {
    path: 'zoomnew',
    loadChildren: () => import('./zoomnew/zoomnew.module').then( m => m.ZoomnewPageModule)
  },
  {
    path: 'webinarpage',
    loadChildren: () => import('./webinarpage/webinarpage.module').then( m => m.WebinarpagePageModule)
  },
  // {
  //   path: 'splash',
  //   loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  // },
  
 
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
